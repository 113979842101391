import {
    type GameAttributes,
    thriveGameSlice,
    useAppDispatch,
    useAppSelector,
    type UserProgress
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useState } from 'react'
import {
    type GetCurrentGameProgressQuery,
    useGetCurrentGameProgressQuery
} from '../../graphql/generated/autogenerated'
import {
    getThriveGameStoreInitialState,
    selectThriveGameProgress
} from '../../slices/thriveGame'

/**
 * This hook is used to sync the game progress state with the store.
 * It will refetch the data when the refetchToken changes.
 * @param refetchToken - The token to refetch the data.
 * @example
 * ```ts
 * useSyncGameProgressState({ refetchToken: new Date().getTime() })
 * ```
 * @returns The game progress state.
 */
export const useSyncGameProgressState = (
    {
        refetchToken: _refetchToken = null,
        skipOnMount = false
    }: {
        refetchToken?: string | number | null
        skipOnMount?: boolean
    } = { refetchToken: null, skipOnMount: false }
): [() => void, { data: UserProgress | null; isLoading: boolean }] => {
    const [refetchToken, setRefetchToken] = useState<string | number | null>(
        _refetchToken
    )

    const dispatch = useAppDispatch()
    const { data, refetch, loading } = useGetCurrentGameProgressQuery({
        skip: skipOnMount && !refetchToken
    })

    useEffect(
        function updateStoreOnDataChange() {
            if (data) {
                const gameActions = thriveGameSlice.actions

                const gameAttributes = selectGameAttributesFromQuery(data)
                dispatch(gameActions.setGameAttributes(gameAttributes))

                const userProgress = selectUserProgressFromQuery(data)
                dispatch(gameActions.setUserProgress(userProgress))
            }
        },
        [data, dispatch]
    )

    useEffect(
        function updateRefetchToken() {
            setRefetchToken(_refetchToken)
        },
        [_refetchToken]
    )

    useEffect(
        function refetchOnTokenChange() {
            if (refetchToken) {
                refetch()
            }
        },
        // TODO: I have doubts about refetch dependency
        // because I remember it was causing issues due to Apollo Client which maybe? always returns new refetch function
        [refetchToken, refetch]
    )

    const triggerRefetch = useCallback(() => {
        setRefetchToken(new Date().getTime())
    }, [])

    const gameProgress = useAppSelector(selectThriveGameProgress) || null

    return [triggerRefetch, { data: gameProgress, isLoading: loading }]
}

export function selectGameAttributesFromQuery(
    progressQuery: GetCurrentGameProgressQuery
): GameAttributes | null {
    if (!progressQuery?.game?.getCurrentGameProgress) {
        return null
    }

    return {
        id: progressQuery.game.getCurrentGameProgress.gameId || null
    }
}

export function selectUserProgressFromQuery(
    progressQuery: GetCurrentGameProgressQuery
): UserProgress | null {
    if (!progressQuery?.game?.getCurrentGameProgress) {
        return null
    }

    const {
        currentLevelNumber,
        pointsProgress,
        nextLevelPointsRequired,
        currentLevelPointsRequired,
        gameId
    } = progressQuery.game.getCurrentGameProgress

    if (hasNoActiveGame({ id: gameId })) {
        return getThriveGameStoreInitialState().userProgress
    }

    return {
        currentLevel: currentLevelNumber,
        currentLevelPointsRequired: currentLevelPointsRequired,
        currentPoints: pointsProgress,
        nextLevelPointsRequired: nextLevelPointsRequired,

        isGameCompleted: nextLevelPointsRequired === null,
        nextLevel:
            nextLevelPointsRequired !== null ? currentLevelNumber + 1 : null
    }
}

/**
 * This function is used to check if the game is active.
 * @param gameAttributes - The game attributes.
 * @param userProgress - The user progress.
 * @returns True if the game is active, false otherwise.
 */
export function hasNoActiveGame(
    gameAttributes: GameAttributes | null = null,
    userProgress: UserProgress | null = null
) {
    // Primary check to see if the game is active.
    if (gameAttributes) {
        if (gameAttributes.id) {
            return false
        }
    }

    // Fallback check to see if the game is active by checking the user progress.
    if (userProgress) {
        const hasNoPointsRequiredToLevelUp =
            userProgress.currentLevelPointsRequired === 0
        const hasDefaultLevel = userProgress.currentLevel === 1
        const hasNoNextLevel = userProgress.nextLevel === null

        if (hasNoPointsRequiredToLevelUp && hasDefaultLevel && hasNoNextLevel) {
            return true
        } else {
            return false
        }
    }

    return true
}
