import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    ComponentType,
    memo,
    useCallback,
    useMemo,
    useRef,
    useState
} from 'react'
import { FeatureFlags } from '../../constants'
import { useGetPulseCheckin, useSavePulseCheckin } from '../../data/pulse'
import { DailyCheckinStatus } from '../../data/pulse/constants'
import {
    dailyCheckInStatusSelector,
    dispatchDateSelector,
    hasSurveyRespondedSelector,
    recommendAcuteCareSelector,
    surveySelector
} from '../../data/pulse/selectors'
import { FetchPulseCheckInMutation } from '../../graphql/generated/autogenerated'
import PulseContext, { PulseContextType } from './PulseContext'

export default function withPulseProvider<Props>(
    Component: ComponentType<Props>
) {
    return memo((props: Props) => {
        const [pulseResponse, setPulseResponse] =
            useState<FetchPulseCheckInMutation>()

        const { dailyCheckinSurvey, recommendAcuteCare, dispatchDate } =
            useMemo(() => {
                return {
                    dailyCheckinSurvey: surveySelector(pulseResponse),
                    recommendAcuteCare:
                        recommendAcuteCareSelector(pulseResponse),
                    dispatchDate: dispatchDateSelector(pulseResponse)
                }
            }, [pulseResponse])

        const [loading, setLoading] = useState(true)

        const {
            submittedPulseData,
            isLoading: isSavingPulseCheckin,
            error: savePulseCheckinError,
            submit: submitPulseCheckin
        } = useSavePulseCheckin({ dispatchDate: dispatchDate })

        const {
            fetch: fetchPulseCheckin,
            error: fetchPulseCheckinError,
            isLoading: isFetchingPulseCheckin
        } = useGetPulseCheckin()

        const hideDailyCheckIn = useIsFeatureEnabled(
            FeatureFlags.HideDailyCheckIn,
            false,
            true
        )

        const hasDeclutteredHomePage = useIsFeatureEnabled(
            FeatureFlags.HasDeclutteredHomePage,
            false,
            true
        )

        const dailyCheckinSubmitted = useRef(false)

        const onFetchPulseCheckIn = useCallback(async () => {
            setLoading(true)

            let result: FetchPulseCheckInMutation = null
            let hasSurveyResponded = false

            const res = await fetchPulseCheckin()
            result = res.data

            if (result) {
                setPulseResponse(result)
                hasSurveyResponded = hasSurveyRespondedSelector(result)
            }

            setLoading(false)
            return hasSurveyResponded
        }, [fetchPulseCheckin])

        const { responded } = useMemo(() => {
            if (hideDailyCheckIn) {
                // NOTE: should be returned because there is some logic coupled with it
                return {
                    responded: true
                }
            } else if (
                dailyCheckInStatusSelector(pulseResponse) ===
                DailyCheckinStatus.PREVIOUSLY_SAVED
            ) {
                return {
                    responded: hasSurveyRespondedSelector(pulseResponse)
                }
            } else if (
                hasDeclutteredHomePage &&
                (submittedPulseData || isSavingPulseCheckin)
            ) {
                return {
                    responded: true
                }
            } else {
                return {
                    responded: false
                }
            }
        }, [
            pulseResponse,
            hideDailyCheckIn,
            isSavingPulseCheckin,
            hasDeclutteredHomePage,
            submittedPulseData
        ])

        const submitPulseResponse = useCallback(
            ({ option }) => {
                dailyCheckinSubmitted.current = true

                return submitPulseCheckin(option)
            },
            [submitPulseCheckin]
        )

        const state = useMemo<PulseContextType>(
            () => ({
                dailyCheckinSurvey: dailyCheckinSurvey,
                recommendAcuteCare: recommendAcuteCare,
                loading,
                error: Boolean(fetchPulseCheckinError),
                responded,
                fetch: onFetchPulseCheckIn,
                submit: submitPulseResponse,
                dailyCheckinSubmitted: dailyCheckinSubmitted.current,
                isSavingPulseCheckin
            }),
            [
                dailyCheckinSurvey,
                recommendAcuteCare,
                loading,
                fetchPulseCheckinError,
                responded,
                onFetchPulseCheckIn,
                submitPulseResponse,
                isSavingPulseCheckin
            ]
        )

        return (
            <PulseContext.Provider value={state}>
                <Component {...props} />
            </PulseContext.Provider>
        )
    })
}
