import { memo } from 'react'
import { Stack, Card } from '@mui/material'
import { type Quest } from '../hooks/useQuests'
import { Frequency } from '../../../../graphql/generated/autogenerated'
import { QuestProgressBar } from '../QuestProgressBar/QuestProgressBar'
import { QuestActionButton } from './QuestActionButton'
import { QuestPointsIcon } from './QuestPointsIcon'
import { QuestCardHeading } from './QuestCardHeading'
import { QuestCompletionText } from './QuestCompletionText'

interface QuestCardProps {
    quest: Quest
    frequency: Frequency
    onActionForCurrentRoute?: () => void
}

function QuestCard({
    quest,
    frequency,
    onActionForCurrentRoute
}: QuestCardProps) {
    const {
        isCompleted,
        progressPercentage,
        rewardPoints,
        completedSoFar,
        maxCanBeCompleted,
        actionType
    } = quest

    return (
        <Card
            sx={{
                p: 3,
                borderRadius: 1
            }}
        >
            <Stack spacing={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <QuestCardHeading
                        actionType={actionType}
                        maxCanBeCompleted={maxCanBeCompleted}
                        frequency={frequency}
                    />
                    <QuestPointsIcon points={rewardPoints} />
                </Stack>
                <Stack spacing={1}>
                    <QuestProgressBar progress={progressPercentage} />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <QuestCompletionText
                            isCompleted={isCompleted}
                            completedSoFar={completedSoFar}
                            frequency={frequency}
                        />
                    </Stack>
                    <Stack>
                        <QuestActionButton
                            actionType={actionType}
                            isCompleted={isCompleted}
                            onActionForCurrentRoute={onActionForCurrentRoute}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(QuestCard)
