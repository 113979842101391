import { AccordionSummary, type AccordionSummaryProps } from '@mui/material'
import { memo, useMemo } from 'react'

export interface ToggleableAccordionSummaryProps extends AccordionSummaryProps {
    expandable?: boolean
    labelId: string
    detailsId: string
}

function ToggleableAccordionSummary(props: ToggleableAccordionSummaryProps) {
    const { expandable, children, sx, labelId, detailsId, ...rest } = props

    const summaryProps = useMemo<AccordionSummaryProps>(() => {
        return {
            ...(expandable
                ? {
                      'aria-controls': detailsId,
                      id: labelId
                  }
                : {
                      role: undefined,
                      'aria-expanded': undefined,
                      tabIndex: -1,
                      expandIcon: null
                  }),
            ...rest
        }
    }, [detailsId, expandable, labelId, rest])

    return (
        <AccordionSummary
            sx={{
                cursor: expandable ? 'pointer' : 'default',
                userSelect: 'auto',
                p: 0,
                '& .MuiAccordionSummary-content': {
                    margin: 0
                },
                '&:hover:not(.Mui-disabled)': {
                    cursor: expandable ? 'pointer' : 'default'
                },
                ...sx
            }}
            {...summaryProps}
        >
            {children}
        </AccordionSummary>
    )
}

export default memo(ToggleableAccordionSummary)
