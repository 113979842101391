import { Button, Stack } from '@mui/material'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useState } from 'react'
import { QuestOverviewModal } from './QuestOverviewModal/QuestOverviewModal'

const messages = defineMessages({
    learnMoreAriaLabel: {
        defaultMessage: 'Learn how to earn points, opens modal.',
        description: 'Learn how to earn points aria label'
    }
})

export const DailyQuestsProgressFooter = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const { formatMessage } = useIntl()

    const onLearnMore = useCallback(() => {
        setModalOpen(true)
    }, [])

    const onClose = useCallback(() => {
        setModalOpen(false)
    }, [])

    return (
        <Stack alignItems="center" justifyContent="center">
            <Button
                variant="text"
                size="small"
                endIcon={<LeafIcon icon="info-circle" fontSize="small" />}
                onClick={onLearnMore}
                aria-label={formatMessage(messages.learnMoreAriaLabel)}
            >
                <CoreTypography customVariant="buttonSmall">
                    <FormattedMessage
                        defaultMessage="Learn how to earn points"
                        description="Learn how to earn points button label"
                    />
                </CoreTypography>
            </Button>
            <QuestOverviewModal open={modalOpen} onClose={onClose} />
        </Stack>
    )
}
