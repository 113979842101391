import { Stack, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    loadingAriaLabel: {
        defaultMessage: 'Loading daily quests progress',
        description:
            'Accessibility label for daily quests progress loading skeleton'
    }
})

const StyledLevelSection = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`
}))

const StyledQuestSection = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3),
    gap: theme.spacing(3)
}))

export const DailyQuestsProgressSkeleton = () => {
    const { formatMessage } = useIntl()

    return (
        <Stack
            data-testid="daily-quests-progress-skeleton"
            role="status"
            aria-busy="true"
            aria-label={formatMessage(messages.loadingAriaLabel)}
        >
            <StyledLevelSection>
                <Skeleton variant="rectangular" width={200} height={60} />
            </StyledLevelSection>
            <StyledQuestSection>
                <Skeleton variant="rectangular" height={30} />
                <Skeleton variant="rectangular" height={60} />
                <Skeleton variant="rectangular" height={60} />
            </StyledQuestSection>
        </Stack>
    )
}
