import {
    type SavePulseCheckInMutation,
    useSavePulseCheckInMutation
} from '../../graphql/generated/autogenerated'
import { useCallback } from 'react'
import { getPulseChannel } from './utils'
import { PULSE_CHECKIN_SUBMIT_SUPPORTED_CONTENT_TYPES } from './constants'
import { useCheckinDate } from './useCheckinDate'
import { useAutoUpdateGameProgressOnAction } from '../thriveGame/useAutoUpdateGameProgressOnAction'

export type UseSavePulseCheckinParams = {
    //only use dispatchDate directly from DailyCheckInDTO object, do not send custom date
    dispatchDate: string
}

export type SavePulseCheckinMutationFn = ReturnType<
    typeof useSavePulseCheckInMutation
>['0']

export type UseSavePulseCheckinResult = {
    submittedPulseData: SavePulseCheckInMutation
    error: any
    isLoading: boolean
    submit: (optionValue: number) => ReturnType<SavePulseCheckinMutationFn>
}

export function useSavePulseCheckin({
    dispatchDate
}: UseSavePulseCheckinParams): UseSavePulseCheckinResult {
    const pulseChannel = getPulseChannel()
    const currentDate = useCheckinDate()

    const [submitResponse, { error, loading, data }] =
        useSavePulseCheckInMutation()

    const autoUpdateGameProgress = useAutoUpdateGameProgressOnAction()

    const submit = useCallback(
        async (optionValue: number) => {
            const submitDCIResponse = await submitResponse({
                variables: {
                    responseInputData: {
                        responseValue: optionValue,
                        responseDate: dispatchDate,
                        userCurrentDate: currentDate,
                        respondingChannel: pulseChannel,
                        supportedContentTypes:
                            PULSE_CHECKIN_SUBMIT_SUPPORTED_CONTENT_TYPES,
                        // TODO: allow disable this value due to NDX doesn't need trigger this
                        markJourneyDailyStepAsCompleted: true
                    }
                }
            })

            autoUpdateGameProgress()

            return submitDCIResponse
        },
        [
            submitResponse,
            dispatchDate,
            currentDate,
            pulseChannel,
            autoUpdateGameProgress
        ]
    )

    return {
        error,
        isLoading: loading,
        submittedPulseData: data,
        submit: submit
    }
}
