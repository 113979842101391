import { Accordion, AccordionDetails, Divider, Stack } from '@mui/material'
import { type SyntheticEvent, useCallback, useState } from 'react'
import { DailyQuestsProgressFooter } from './DailyQuestsProgressFooter'
import { DailyQuestsProgressSkeleton } from './DailyQuestsProgressSkeleton'
import { LevelInfo } from './LevelInfo'
import { QuestList } from './QuestList'
import { useQuests } from './hooks/useQuests'
import ToggleableAccordionSummary from '../../elements/ToggleableAccordionSummary/ToggleableAccordionSummary'
export interface DailyQuestsProgressProps {
    expandable?: boolean
    currentPoints: number
    currentLevel: number
    pointsRequiredToLevelUp: number | null
}

export const DailyQuestsProgress = ({
    currentPoints,
    currentLevel,
    pointsRequiredToLevelUp,
    expandable = false
}: DailyQuestsProgressProps) => {
    const [isExpanded, setIsExpanded] = useState(true)

    const handleAccordionStateChange = useCallback(
        (event: SyntheticEvent, expanded: boolean) => {
            setIsExpanded(expanded)
        },
        []
    )

    const [questsVisibilityOption, setQuestsVisibilityOption] = useState<
        'all' | number
    >(expandable ? 1 : 'all')

    const handleShowAllQuests = useCallback(() => {
        setQuestsVisibilityOption('all')
    }, [])

    const { quests, allQuests, loading } = useQuests({
        maxUncompletedQuests: questsVisibilityOption
    })
    const isAllQuestsVisible = quests.length === allQuests.length

    if (loading) {
        return <DailyQuestsProgressSkeleton />
    }

    const expanded = expandable ? isExpanded : true
    const accordionLabelId = `daily-quests-progress-accordion-label`
    const accordionDetailsId = `daily-quests-progress-accordion-details`

    return (
        <Accordion
            expanded={expanded}
            onChange={handleAccordionStateChange}
            disableGutters
            sx={{
                p: 3
            }}
        >
            <ToggleableAccordionSummary
                expandable={expandable}
                labelId={accordionLabelId}
                detailsId={accordionDetailsId}
                sx={{
                    minHeight: 'auto',
                    '& .MuiAccordionSummary-content': {
                        pr: expandable ? 0.75 : 0,
                        m: 0
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        alignSelf: 'baseline'
                    }
                }}
            >
                <LevelInfo
                    currentLevel={currentLevel}
                    currentPoints={currentPoints}
                    pointsRequiredToLevelUp={pointsRequiredToLevelUp}
                />
            </ToggleableAccordionSummary>
            <AccordionDetails
                sx={{ p: 0 }}
                {...(expandable
                    ? {
                          id: accordionDetailsId,
                          'aria-labelledby': accordionLabelId
                      }
                    : {})}
            >
                <Divider sx={{ px: 3, mt: 1.5, mb: 2 }} role="presentation" />
                <QuestList
                    quests={quests}
                    onShowAllQuests={
                        isAllQuestsVisible ? null : handleShowAllQuests
                    }
                />
                <Stack sx={{ pt: 2 }}>
                    <DailyQuestsProgressFooter />
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}
