import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl, MessageDescriptor } from 'react-intl'
import { Frequency } from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    completed: {
        defaultMessage: 'Completed',
        description: 'Status text shown when a quest is completed'
    },
    notEarnedYetToday: {
        defaultMessage: 'Not earned yet today',
        description: 'Status text shown when a quest is not earned yet today'
    },
    notEarnedYetQuarterly: {
        defaultMessage: 'Not earned yet this quarter',
        description:
            'Status text shown when a quest is not earned yet this quarter'
    },
    notEarnedYetOneTime: {
        defaultMessage: 'Not earned yet',
        description: 'Status text shown when a quest is not earned yet'
    },
    inProgressDaily: {
        defaultMessage: 'Earned {completed}x today',
        description: 'Status text shown when a daily quest is in progress'
    },
    inProgressQuarterly: {
        defaultMessage: 'Earned {completed}x this quarter',
        description: 'Status text shown when a quarterly quest is in progress'
    },
    inProgressOneTime: {
        defaultMessage: 'In progress',
        description: 'Status text shown when a one-time quest is in progress'
    }
})

const DEFAULT_FREQUENCY = Frequency.TimeWindowBased

const NOT_EARNED_MESSAGES: Record<Frequency, MessageDescriptor> = {
    [Frequency.TimeWindowBased]: messages.notEarnedYetToday,
    [Frequency.PerGame]: messages.notEarnedYetQuarterly,
    [Frequency.Once]: messages.notEarnedYetOneTime,
    [Frequency.Seasonal]: messages.notEarnedYetQuarterly // Using quarterly message for seasonal
}

const IN_PROGRESS_MESSAGES: Record<Frequency, MessageDescriptor> = {
    [Frequency.TimeWindowBased]: messages.inProgressDaily,
    [Frequency.PerGame]: messages.inProgressQuarterly,
    [Frequency.Once]: messages.inProgressOneTime,
    [Frequency.Seasonal]: messages.inProgressQuarterly // Using quarterly message for seasonal
}

export function getProgressMessage(
    completedSoFar: number,
    frequency: Frequency
): MessageDescriptor {
    if (completedSoFar === 0) {
        return (
            NOT_EARNED_MESSAGES[frequency] ||
            NOT_EARNED_MESSAGES[DEFAULT_FREQUENCY]
        )
    }

    return (
        IN_PROGRESS_MESSAGES[frequency] ||
        IN_PROGRESS_MESSAGES[DEFAULT_FREQUENCY]
    )
}

export interface QuestCompletionTextProps {
    isCompleted: boolean
    completedSoFar: number
    frequency: Frequency
}

export function QuestCompletionText({
    isCompleted,
    completedSoFar,
    frequency
}: QuestCompletionTextProps) {
    const { formatMessage } = useIntl()

    return (
        <CoreTypography variant="body2" color="text.secondary">
            {isCompleted
                ? formatMessage(messages.completed)
                : formatMessage(
                      getProgressMessage(completedSoFar, frequency),
                      completedSoFar > 0
                          ? {
                                completed: completedSoFar
                            }
                          : undefined
                  )}
        </CoreTypography>
    )
}
