import { useCallback } from 'react'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    defaultFocusAreasServedSuggestedProps,
    defaultFocusAreasSetProps,
    defaultIntentionSelectedProps,
    defaultPromptSelectedProps
} from './defaultTrackingProps'
import { type Journey } from '../graphql/generated/autogenerated'
import { FocusAreasValueType } from '@thriveglobal/thrive-web-tracking/dist/Avo'

export function useTrackStartIntentionSettingFlow() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'get_started_selected'
        })
    }, [])

    return track
}

export function useTrackSkipSetIntentionFlow() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'not_now_selected'
        })
    }, [])

    return track
}

export function useTrackSetIntentionCTAClick() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'try_it_now_selected'
        })
    }, [])

    return track
}

export function useTrackCloseIntentionSettingDialog() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'close_button_selected'
        })
    }, [])

    return track
}

export function useTrackEditIntentionButtonClick() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'intention_banner_edit_selected'
        })
    }, [])

    return track
}

export function useTrackClearIntention({
    source
}: {
    source: 'journeys' | 'onboarding'
}) {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'clear_intention_selected',
            isOnboarding: source === 'onboarding'
        })
    }, [source])

    return track
}

export const CHOOSE_FOR_ME_INTENTION_STRING = "I don't know where to start"
export function useTrackSelectPredefinedIntention({
    source
}: {
    source: 'journeys' | 'onboarding'
}) {
    const track = useCallback(
        ({ intentionString }: { intentionString: string }) => {
            Avo.intentionSelected({
                ...defaultIntentionSelectedProps,
                featureType: 'intention_setting',
                activityType: 'pre_selected_response',
                responseText: intentionString,
                isOnboarding: source === 'onboarding'
            })
        },
        [source]
    )

    return track
}

export function useTrackUserIntention({
    source
}: {
    source: 'journeys' | 'onboarding'
}) {
    const track = useCallback(
        ({ intentionString }: { intentionString: string }) => {
            Avo.intentionSelected({
                ...defaultIntentionSelectedProps,
                featureType: 'intention_setting',
                activityType: 'user_written_response',
                responseText: intentionString,
                isOnboarding: source === 'onboarding'
            })
        },
        [source]
    )

    return track
}

export function useTrackFocusAreasServed({
    source
}: {
    source: 'journeys' | 'onboarding'
}) {
    const track = useCallback(
        (journeys: Journey[]) => {
            const focusAreas: FocusAreasValueType[] = journeys.map(
                (journey) =>
                    journey.coreType.toUpperCase() as FocusAreasValueType
            )

            Avo.focusAreasSuggested({
                ...defaultFocusAreasServedSuggestedProps,
                featureType: 'intention_setting',
                activityType: 'focus_areas_suggested',
                focusAreas: focusAreas,
                isOnboarding: source === 'onboarding'
            })
        },
        [source]
    )

    return track
}

export function useTrackFocusAreasSet({
    source
}: {
    source: 'journeys' | 'onboarding'
}) {
    const track = useCallback(
        ({
            journeys,
            isChooseForMeOption = false
        }: {
            journeys: Journey[]
            isChooseForMeOption?: boolean
        }) => {
            const focusAreas: FocusAreasValueType[] = journeys.map(
                (journey) =>
                    journey.coreType.toUpperCase() as FocusAreasValueType
            )
            const responseText = isChooseForMeOption ? 'Choose for me' : ''

            Avo.focusAreasSet({
                ...defaultFocusAreasSetProps,
                featureType: 'intention_setting',
                activityType: 'personalized_plan_selected',
                focusAreas: focusAreas,
                isOnboarding: source === 'onboarding',
                responseText: responseText
            })
        },
        [source]
    )

    return track
}

export function useGoBackFromIntentionSetting() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'back_selected'
        })
    }, [])

    return track
}
