export enum FeatureFlags {
    OnboardingFlow = 'newOnboardingFlow',
    HideDailyCheckIn = 'journeysHideDailyCheckIn',
    HasAcuteCareAccess = 'hasAcuteCareAccess',
    isNewDailyExperienceEnabled = 'isNewDailyExperienceEnabled',
    HasIntentionSetting = 'HasIntentionSetting',
    hasResetPostWatchScreen = 'isResetFeedbackEnabled',
    IsNewCompanyResourceEnabled = 'isNewCompanyResourceCardEnabled',
    HasBaselineAssessmentEnabled = 'hasBaselineAssessmentEnabled',
    HasDeclutteredHomePage = 'hasDeclutteredHomePage',
    isThriveGameEnabled = 'isThriveGameEnabled',
    hasOnboardingV2Flow = 'hasOnboardingV2Flow',
    onboardingNumberOfUsers = 'onboardingNumberOfUsers'
}
