import { Stack } from '@mui/material'
import { CoreTypography, LeafChip } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import { ThriveSvgIcon } from '../../GameProgressBadge/ThriveSvgIcon'
import { TEMP_GAME_COLOR } from '../LevelInfo'

const messages = defineMessages({
    points: {
        defaultMessage: '+{points}',
        description: 'Points reward for completing the quest'
    },
    pointsAriaLabel: {
        defaultMessage: 'Earn {points} points',
        description: 'Aria label for points reward'
    }
})

export interface QuestPointsIconProps {
    points?: number
}

export function QuestPointsIcon({ points }: QuestPointsIconProps) {
    const { formatMessage } = useIntl()

    // Don't render if points are not positive
    if (!points || points <= 0) {
        return null
    }

    return (
        <LeafChip
            icon={
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <CoreTypography
                        variant="body2"
                        color="text.secondary"
                        aria-label={formatMessage(messages.pointsAriaLabel, {
                            points
                        })}
                    >
                        {formatMessage(messages.points, { points })}
                    </CoreTypography>
                    <ThriveSvgIcon fill={TEMP_GAME_COLOR} />
                </Stack>
            }
            color="secondary"
            variant="filled"
            sx={{
                '.MuiChip-label': {
                    paddingLeft: 0
                }
            }}
        />
    )
}
