import { Card, Skeleton, Stack } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
    loadingAriaLabel: {
        defaultMessage: 'Loading quest card',
        description: 'Accessibility label for quest card loading skeleton'
    }
})

export function QuestCardSkeleton() {
    const { formatMessage } = useIntl()

    return (
        <Card
            role="status"
            aria-busy="true"
            aria-label={formatMessage(messages.loadingAriaLabel)}
            sx={{
                p: 3,
                borderRadius: 1
            }}
            data-testid="quest-card-skeleton"
        >
            <Stack spacing={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Stack spacing={0.5} flex={1}>
                        {/* Title skeleton */}
                        <Skeleton
                            data-testid="title-skeleton"
                            variant="text"
                            width="60%"
                            height={32}
                        />
                        {/* Subtitle skeleton */}
                        <Skeleton
                            data-testid="subtitle-skeleton"
                            variant="text"
                            width="40%"
                        />
                    </Stack>
                    {/* Points chip skeleton */}
                    <Skeleton
                        data-testid="points-chip-skeleton"
                        variant="rounded"
                        width={80}
                        height={32}
                    />
                </Stack>
                <Stack spacing={1}>
                    {/* Progress bar skeleton */}
                    <Skeleton
                        data-testid="progress-bar-skeleton"
                        variant="rounded"
                        width="100%"
                        height={8}
                        sx={{
                            borderRadius: 4
                        }}
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {/* Progress text skeleton */}
                        <Skeleton
                            data-testid="progress-text-skeleton"
                            variant="text"
                            width="30%"
                        />
                        {/* Button skeleton */}
                        <Skeleton
                            data-testid="button-skeleton"
                            variant="rounded"
                            width={120}
                            height={32}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}
