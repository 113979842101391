import { Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import { ThriveSvgIcon } from '../../../components/features/GameProgressBadge/ThriveSvgIcon'

const POINTS_AWAY_FROM_NEXT_LEVEL_TEASER_THRESHOLD = 150

export interface LevelInfoProps {
    currentLevel: number
    currentPoints: number
    pointsRequiredToLevelUp: number | null
}
// TODO: Replace with the color from the DS
export const TEMP_GAME_COLOR = '#9870E5'

export const LevelInfo = ({
    currentLevel,
    currentPoints,
    pointsRequiredToLevelUp
}: LevelInfoProps) => {
    const theme = useTheme()

    const nextLevel = pointsRequiredToLevelUp ? currentLevel + 1 : null

    const pointsAwayFromNextLevel =
        nextLevel !== null ? pointsRequiredToLevelUp - currentPoints : null

    const isThresholdReached =
        nextLevel !== null &&
        pointsAwayFromNextLevel <= POINTS_AWAY_FROM_NEXT_LEVEL_TEASER_THRESHOLD

    const isTeaserVisible = nextLevel !== null && isThresholdReached

    return (
        <Stack sx={{ gap: 2, width: '100%' }}>
            <Stack
                sx={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Stack
                    direction="row"
                    alignItems="start"
                    gap={1}
                    sx={{ width: '100%' }}
                >
                    <Stack>
                        {/* TODO: Replace with the color from the DS */}
                        <ThriveSvgIcon fill={TEMP_GAME_COLOR} />
                    </Stack>
                    <Stack>
                        <CoreTypography variant="body2" color="text.secondary">
                            <FormattedMessage
                                defaultMessage="{points} points"
                                description="User's current point total"
                                values={{ points: currentPoints }}
                            />
                        </CoreTypography>
                    </Stack>
                    <Stack
                        sx={{
                            marginLeft: 'auto'
                        }}
                    >
                        <CoreTypography variant="h6">
                            <FormattedMessage
                                defaultMessage="Level {level}"
                                description="User's current game level"
                                values={{ level: currentLevel }}
                            />
                        </CoreTypography>
                    </Stack>
                </Stack>
            </Stack>

            {isTeaserVisible && (
                <CoreTypography variant="body2">
                    <FormattedMessage
                        defaultMessage="You are {points} points away from level {level}"
                        description="User's current point total"
                        values={{
                            points: pointsAwayFromNextLevel,
                            level: nextLevel
                        }}
                    />
                </CoreTypography>
            )}
        </Stack>
    )
}
