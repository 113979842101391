import { createSelector, type CombinedState } from '@reduxjs/toolkit'
import {
    type ThriveGameState,
    thriveGameSlice
} from '@thriveglobal/thrive-web-core'

export const thriveGameSliceName = thriveGameSlice.name

export type RootStateWithThriveGameState = CombinedState<{
    [thriveGameSliceName]: ThriveGameState
}>

export const selectThriveGameState = (
    rootState: RootStateWithThriveGameState
) => {
    return rootState[thriveGameSliceName]
}

export const selectThriveGameProgress = createSelector(
    selectThriveGameState,
    (state) => state.userProgress
)

export const selectThriveGameAttributes = createSelector(
    selectThriveGameState,
    (state) => state.gameAttributes
)

export const getThriveGameStoreInitialState = () => {
    return thriveGameSlice.getInitialState()
}
