import { Box } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { motion } from 'framer-motion'
import { memo, useLayoutEffect, useRef, useState, type ReactNode } from 'react'

const DEFAULT_PROGRESS_WIDTH = 3

export type ProgressIndicatorProps = {
    currentProgress: number
    totalProgress: number
    children: ReactNode
    progressColor?: string
    backgroundColor?: string
    borderRadius?: number
    progressWidth?: number
}

function getIndicatorLineSvgPath(
    width: number,
    height: number,
    progressWidth: number
) {
    const radius = height / 2
    const centerX = width / 2

    const path = `
        M ${centerX} ${progressWidth / 2}
        h ${width / 2 - radius}
        a ${radius - progressWidth / 2} ${radius - progressWidth / 2} 0 0 1 0 ${
        height - progressWidth
    }
        h -${width - 2 * radius}
        a ${radius - progressWidth / 2} ${
        radius - progressWidth / 2
    } 0 0 1 0 -${height - progressWidth}
        h ${width / 2 - radius}
    `

    return path
}

export const ProgressIndicator = (props: ProgressIndicatorProps) => {
    const {
        currentProgress,
        totalProgress,

        children,
        progressColor,
        backgroundColor,
        borderRadius: _borderRadius,
        progressWidth = DEFAULT_PROGRESS_WIDTH
    } = props

    const containerRef = useRef<HTMLDivElement>(null)
    const [svgPath, setSvgPath] = useState('')

    const radius = _borderRadius

    const { palette } = useTheme()

    // Calculate progress percentage
    const progressPercentage = Math.min(
        100,
        (currentProgress / totalProgress) * 100
    )

    // Generate SVG path based on container dimensions
    useLayoutEffect(() => {
        if (!containerRef.current) {
            return
        }

        const indicatorContainer = containerRef.current

        function updateSvgPath() {
            // progressWidth is added to the container to ensure the line is visible
            if (!indicatorContainer) {
                return
            }

            const containerWidth =
                indicatorContainer.offsetWidth + progressWidth
            const containerHeight =
                indicatorContainer.offsetHeight + progressWidth

            const path = getIndicatorLineSvgPath(
                containerWidth,
                containerHeight,
                progressWidth
            )

            setSvgPath(path)
        }

        const resizeObserver = new ResizeObserver(updateSvgPath)
        resizeObserver.observe(indicatorContainer)

        // Update the SVG path when the component mounts
        updateSvgPath()

        return () => {
            resizeObserver.unobserve(indicatorContainer)
            resizeObserver.disconnect()
        }
    }, [progressWidth])

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'relative',
                display: 'flex',
                // This is to ensure the children are above the background pill
                '& > :first-child': {
                    zIndex: 1
                }
            }}
        >
            {children}

            {/* Background pill */}
            <Box
                sx={{
                    position: 'absolute',
                    top: -progressWidth / 2,
                    left: -progressWidth / 2,
                    width: `calc(100% + ${progressWidth}px)`,
                    height: `calc(100% + ${progressWidth}px)`,
                    borderRadius: radius,
                    backgroundColor
                }}
            />

            {/* Progress indicator */}
            <svg
                style={{
                    position: 'absolute',
                    top: -progressWidth / 2,
                    left: -progressWidth / 2,
                    width: `calc(100% + ${progressWidth}px)`,
                    height: `calc(100% + ${progressWidth}px)`,
                    overflow: 'hidden'
                }}
            >
                {svgPath && (
                    <motion.path
                        d={svgPath}
                        fill="none"
                        stroke={progressColor || palette.primary.main}
                        strokeWidth={progressWidth}
                        strokeLinecap="square"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: progressPercentage / 100 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                    />
                )}
            </svg>
        </Box>
    )
}

export default memo(ProgressIndicator)
