import { LinearProgress } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'

interface QuestProgressBarProps {
    progress: number
    ariaLabelId?: string
}

const messages = defineMessages({
    progressBarAriaLabel: {
        defaultMessage: 'Percentage of actions completed',
        description: 'Quest progress bar aria label'
    }
})

export function QuestProgressBar({
    progress,
    ariaLabelId
}: QuestProgressBarProps) {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    return (
        <LinearProgress
            variant="determinate"
            value={progress}
            {...(ariaLabelId
                ? {
                      'aria-labelledby': ariaLabelId
                  }
                : {
                      'aria-label': formatMessage(messages.progressBarAriaLabel)
                  })}
            sx={{
                height: theme.spacing(2),
                borderRadius: theme.spacing(1.5)
            }}
        />
    )
}
