import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    type Journey,
    useGetAcuteCareQuery,
    useGetAcuteCareResourceLazyQuery,
    useMarkAcuteCareResourceAsSeenMutation,
    type UserJourneyProgress
} from '../../../graphql/generated/autogenerated'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    useTrackAcuteCareSelected,
    useTrackAcuteCareViewed
} from '../../../tracking/acuteCare'
import { FeatureFlags } from '../../../constants'

type MarkAsSeenProp = {
    contentId: string
}

type TrackAcuteCareSelectedProp = {
    contentId: string
    careOption?: string
}

export default function useAcuteCare() {
    const hasAcuteCareAccess = useIsFeatureEnabled(
        FeatureFlags.HasAcuteCareAccess,
        false,
        true
    )

    const trackViewedAcuteCareCard = useTrackAcuteCareViewed()
    const trackAcuteCareSelected = useTrackAcuteCareSelected()

    const [markAcuteCareAsSeen] = useMarkAcuteCareResourceAsSeenMutation()

    const [isDisplayingAcuteCareCard, setIsDisplayAcuteCareCard] =
        useState(false)

    const { data: acuteCareBanner, loading: bannerLoading } =
        useGetAcuteCareQuery()

    const [
        getAcuteCareResources,
        { data: acuteCareResources, loading: resourcesLoading }
    ] = useGetAcuteCareResourceLazyQuery()

    const handleMarkAcuteCareAsSeen = useCallback(
        async (variables: MarkAsSeenProp) => {
            await markAcuteCareAsSeen({
                variables
            })
        },
        [markAcuteCareAsSeen]
    )

    const getResources = useCallback(
        async (pulseDimensionType: string, pulseSurveyId: string) => {
            await getAcuteCareResources({
                variables: {
                    pulseDimensionType,
                    pulseSurveyId
                }
            })
        },
        [getAcuteCareResources]
    )

    const banner = useMemo(
        () => acuteCareBanner?.contentExperience?.getAcuteCareBanner ?? null,
        [acuteCareBanner?.contentExperience?.getAcuteCareBanner]
    )
    const resources = useMemo(
        () =>
            acuteCareResources?.contentExperience?.getBenefitsConnectGroup ??
            null,
        [acuteCareResources?.contentExperience?.getBenefitsConnectGroup]
    )

    const _isDisplayingAcuteCareCard = useMemo(() => {
        return isDisplayingAcuteCareCard
    }, [isDisplayingAcuteCareCard])

    const showAcuteCare = useMemo(() => {
        return hasAcuteCareAccess && resources && !resourcesLoading
    }, [hasAcuteCareAccess, resources, resourcesLoading])

    const handleAcuteCareBannerClick = useCallback(() => {
        setIsDisplayAcuteCareCard(true)
    }, [])

    const handleAcuteCareCardBackClick = useCallback(() => {
        if (!resources?.alreadySeenToday) {
            handleMarkAcuteCareAsSeen({ contentId: resources.uuid })
        }
        setIsDisplayAcuteCareCard(false)
    }, [handleMarkAcuteCareAsSeen, resources])

    useEffect(() => {
        if (resources && !resources?.alreadySeenToday) {
            setIsDisplayAcuteCareCard(true)
        }
    }, [resources])

    const handleViewedAcuteCareCard = useCallback(
        ({ contentId }: TrackAcuteCareSelectedProp) => {
            trackViewedAcuteCareCard({
                contentId
            })
        },
        [trackViewedAcuteCareCard]
    )

    const handleTrackAcuteCardSelected = useCallback(
        ({ contentId, careOption }: TrackAcuteCareSelectedProp) => {
            trackAcuteCareSelected({
                contentId,
                careOption: careOption
            })
        },
        [trackAcuteCareSelected]
    )

    const showAcuteCareCard = showAcuteCare && _isDisplayingAcuteCareCard
    const showAcuteCareBanner =
        showAcuteCare && banner && !_isDisplayingAcuteCareCard

    return {
        showAcuteCareCard,
        showAcuteCareBanner,
        resources,
        banner,
        acuteCareLoading: resourcesLoading || bannerLoading,
        getResources,
        handleAcuteCareBannerClick,
        handleAcuteCareCardBackClick,
        handleViewedAcuteCareCard,
        handleTrackAcuteCardSelected
    }
}
