import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import { ActionLabel } from '../ActionLabel'
import {
    ActionType,
    Frequency
} from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    earnUpToDaily: {
        defaultMessage: 'Earn up to {max}x a day',
        description:
            'Text showing how many times a daily quest can be completed'
    },
    earnUpToQuarterly: {
        defaultMessage: 'Earn up to {max}x per quarter',
        description:
            'Text showing how many times a quarterly quest can be completed'
    },
    earnUpToOneTime: {
        defaultMessage: 'One-time quest',
        description: 'Text showing this is a one-time quest'
    }
})

export interface QuestCardHeadingProps {
    actionType: ActionType
    maxCanBeCompleted: number
    frequency: Frequency
}

export function QuestCardHeading({
    actionType,
    maxCanBeCompleted,
    frequency
}: QuestCardHeadingProps) {
    const { formatMessage } = useIntl()

    const getFrequencyMessage = () => {
        switch (frequency) {
            case Frequency.TimeWindowBased:
                return messages.earnUpToDaily
            case Frequency.PerGame:
                return messages.earnUpToQuarterly
            case Frequency.Once:
                return messages.earnUpToOneTime
            default:
                return messages.earnUpToDaily
        }
    }

    return (
        <Stack spacing={0.5} flex={1}>
            <CoreTypography variant="h6">
                <ActionLabel
                    requiredCount={maxCanBeCompleted}
                    actionType={actionType}
                />
            </CoreTypography>
            <CoreTypography variant="body2" color="text.secondary">
                {formatMessage(getFrequencyMessage(), {
                    max: maxCanBeCompleted
                })}
            </CoreTypography>
        </Stack>
    )
}
