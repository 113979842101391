import { Box, Stack, Tabs } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'
import { CoreTab } from '@thriveglobal/thrive-web-leafkit'
import { SyntheticEvent, useState } from 'react'
import { Frequency } from '../../../../graphql/generated/autogenerated'
import { QuestTabContent } from './QuestTabContent'

type QuestTabId = 'daily' | 'quarterly' | 'one-time'

const messages = defineMessages({
    questTabsAria: {
        defaultMessage: 'Quest tabs',
        description: 'Aria label for quest tabs'
    },
    dailyTab: {
        defaultMessage: 'Daily',
        description: 'Daily quests tab label'
    },
    dailyTabAria: {
        defaultMessage: 'Open daily quests tab',
        description: 'Aria label for daily quests tab'
    },
    quarterlyTab: {
        defaultMessage: 'Quarterly',
        description: 'Quarterly quests tab label'
    },
    quarterlyTabAria: {
        defaultMessage: 'Open quarterly quests tab',
        description: 'Aria label for quarterly quests tab'
    },
    oneTimeTab: {
        defaultMessage: 'One-Time',
        description: 'One-time quests tab label'
    },
    oneTimeTabAria: {
        defaultMessage: 'Open one-time quests tab',
        description: 'Aria label for one-time quests tab'
    }
})

interface TabPanelProps {
    children?: React.ReactNode
    value: QuestTabId
    tabId: QuestTabId
}

function TabPanel(props: TabPanelProps) {
    const { children, value, tabId, ...other } = props
    const panelId = `quest-tabpanel-${tabId}`
    const labelledById = `quest-tab-${tabId}`

    return (
        <div
            role="tabpanel"
            hidden={value !== tabId}
            id={panelId}
            aria-labelledby={labelledById}
            {...other}
        >
            {value === tabId && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

export interface QuestTabsProps {
    onActionForCurrentRoute: () => void
}

export function QuestTabs({ onActionForCurrentRoute }: QuestTabsProps) {
    const [activeTab, setActiveTab] = useState<QuestTabId>('daily')
    const intl = useIntl()

    const handleTabChange = (_event: SyntheticEvent, newValue: QuestTabId) => {
        setActiveTab(newValue)
    }

    return (
        <Stack spacing={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label={intl.formatMessage(messages.questTabsAria)}
                >
                    <CoreTab
                        label={intl.formatMessage(messages.dailyTab)}
                        value="daily"
                        aria-label={intl.formatMessage(messages.dailyTabAria)}
                    />
                    <CoreTab
                        label={intl.formatMessage(messages.quarterlyTab)}
                        value="quarterly"
                        aria-label={intl.formatMessage(
                            messages.quarterlyTabAria
                        )}
                    />
                    <CoreTab
                        label={intl.formatMessage(messages.oneTimeTab)}
                        value="one-time"
                        aria-label={intl.formatMessage(messages.oneTimeTabAria)}
                    />
                </Tabs>
            </Box>

            <TabPanel value={activeTab} tabId="daily">
                <QuestTabContent
                    frequency={Frequency.TimeWindowBased}
                    onActionForCurrentRoute={onActionForCurrentRoute}
                />
            </TabPanel>
            <TabPanel value={activeTab} tabId="quarterly">
                <QuestTabContent
                    frequency={Frequency.PerGame}
                    onActionForCurrentRoute={onActionForCurrentRoute}
                />
            </TabPanel>
            <TabPanel value={activeTab} tabId="one-time">
                <QuestTabContent
                    frequency={Frequency.Once}
                    onActionForCurrentRoute={onActionForCurrentRoute}
                />
            </TabPanel>
        </Stack>
    )
}
