import { Box, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { forwardRef, memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ActionLabel } from './ActionLabel'
import { Quest } from './hooks/useQuests'
import { useNavigation } from '@thriveglobal/thrive-web-core'
import { ActionType } from '../../../graphql/generated/autogenerated'
import { RESET_ROUTES } from '../../../routes/routes'
import { QuestProgressBar } from './QuestProgressBar/QuestProgressBar'

const messages = defineMessages({
    completedAria: {
        defaultMessage: 'Daily quest completed!',
        description: 'Quest completed count'
    },
    inProgressAria: {
        defaultMessage: '{completed} out of {required} actions completed',
        description: 'Quest in progress count'
    }
})

type QuestRedirectOptions = {
    link: string
}

const QUEST_ACTION_TYPE_TO_REDIRECT_MAP = {
    [ActionType.ResetWatched]: {
        link: RESET_ROUTES.RESETS
    }
} as const

function getQuestRedirectOptions(
    questActionType: ActionType
): QuestRedirectOptions | null {
    const redirectConfig = QUEST_ACTION_TYPE_TO_REDIRECT_MAP[questActionType]
    return redirectConfig ?? null
}

interface QuestListItemProps {
    quest: Quest
}

const QuestListItem = forwardRef<HTMLDivElement, QuestListItemProps>(
    (props, ref) => {
        const { quest } = props
        const { spacing, palette } = useTheme()
        const intl = useIntl()

        const navigate = useNavigation()
        const redirectOptions = getQuestRedirectOptions(quest.actionType)

        const progress = (quest.completedSoFar / quest.maxCanBeCompleted) * 100
        const isCompleted = quest.completedSoFar >= quest.maxCanBeCompleted

        const hasRedirectSupport = !!redirectOptions

        const actionLabelId = `action-label-${quest.id}`

        return (
            <Stack
                ref={ref}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: spacing(1.25),
                    color: palette.text.primary,
                    textDecoration: 'none'
                }}
                component={hasRedirectSupport ? 'a' : 'div'}
                href={hasRedirectSupport ? redirectOptions.link : undefined}
                onClick={
                    hasRedirectSupport
                        ? (event) => {
                              event.preventDefault()
                              navigate(redirectOptions.link)
                          }
                        : undefined
                }
            >
                <Box
                    role="presentation"
                    sx={{
                        // TODO: Align completed icon size with uncompleted box size
                        p: spacing(1),
                        borderRadius: spacing(1),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isCompleted
                            ? palette.primary.light
                            : palette.secondary.light,
                        color: isCompleted
                            ? palette.primary.contrastText
                            : palette.secondary.contrastText
                    }}
                    aria-label={intl.formatMessage(
                        isCompleted
                            ? messages.completedAria
                            : messages.inProgressAria,
                        {
                            completed: quest.completedSoFar,
                            required: quest.maxCanBeCompleted
                        }
                    )}
                >
                    {isCompleted ? (
                        <LeafIcon
                            aria-hidden
                            icon="check"
                            fontSize="small"
                            sx={{
                                minWidth: spacing(3),
                                minHeight: spacing(2.5)
                            }}
                        />
                    ) : (
                        <CoreTypography
                            role="presentation"
                            aria-hidden
                            variant="overline"
                            fontWeight={600}
                            color={palette.text.primary}
                        >
                            {quest.completedSoFar}/{quest.maxCanBeCompleted}
                        </CoreTypography>
                    )}
                </Box>
                <Stack flex={1} gap={spacing(0.5)}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <CoreTypography variant="body2" id={actionLabelId}>
                            <ActionLabel
                                requiredCount={quest.maxCanBeCompleted}
                                actionType={quest.actionType}
                            />
                        </CoreTypography>
                        {hasRedirectSupport && (
                            <LeafIcon icon="chevron-right" fontSize="small" />
                        )}
                    </Stack>
                    <QuestProgressBar
                        ariaLabelId={actionLabelId}
                        progress={progress}
                    />
                </Stack>
            </Stack>
        )
    }
)

export default memo(QuestListItem)
