import { Button, Popover, Stack } from '@mui/material'
import { memo, useCallback, useState, type MouseEvent } from 'react'
import {
    hasNoActiveGame,
    useSyncGameProgressState
} from '../../../data/thriveGame/useSyncGameProgressState'
import { DailyQuestsProgress } from '../DailyQuestsProgress/DailyQuestsProgress'
import GameProgressBadge from '../GameProgressBadge/GameProgressBadge'
import { useAppSelector } from '../../../slices/store'
import {
    selectThriveGameAttributes,
    selectThriveGameProgress
} from '../../../slices/thriveGame'
import { defineMessages, useIntl } from 'react-intl'

const DAILY_QUESTS_PROGRESS_MENU_ID = 'daily-quests-progress-menu'

const messages = defineMessages({
    questProgressAriaLabel: {
        defaultMessage: 'Your current quest progress',
        description: 'Accessibility label for the quest progress popover'
    }
})

function ThriveGameProgressNavWidget() {
    useSyncGameProgressState()

    const userProgress = useAppSelector((state) =>
        selectThriveGameProgress(state)
    )
    const {
        currentPoints,
        currentLevel,
        isGameCompleted,
        nextLevelPointsRequired,
        currentLevelPointsRequired
    } = userProgress ?? {}

    const gameAttributes = useAppSelector((state) =>
        selectThriveGameAttributes(state)
    )

    const hasNoActiveGameForUser = hasNoActiveGame(gameAttributes)

    const { formatMessage } = useIntl()

    const [
        dailyQuestsProgressPopoverAnchorEl,
        setDailyQuestsProgressPopoverAnchorEl
    ] = useState<null | HTMLElement>(null)
    const isDailyQuestsProgressPopoverOpen = Boolean(
        dailyQuestsProgressPopoverAnchorEl
    )

    const handleBadgeClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setDailyQuestsProgressPopoverAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setDailyQuestsProgressPopoverAnchorEl(null)
    }, [])

    // NOTE: If user reached the last level, we don't have max points for the level,
    // so we use the current points as the indicator total points
    const indicatorPoints = isGameCompleted
        ? currentPoints
        : currentPoints - currentLevelPointsRequired
    const indicatorTotalPoints = isGameCompleted
        ? currentPoints
        : nextLevelPointsRequired - currentLevelPointsRequired

    if (hasNoActiveGameForUser) {
        return null
    }

    return (
        <>
            <GameProgressBadge
                points={currentPoints}
                totalPoints={nextLevelPointsRequired}
                isGameFinished={isGameCompleted}
                indicatorPoints={indicatorPoints}
                indicatorTotalPoints={indicatorTotalPoints}
                chipProps={{
                    'aria-controls': isDailyQuestsProgressPopoverOpen
                        ? DAILY_QUESTS_PROGRESS_MENU_ID
                        : undefined,
                    'aria-haspopup': true,
                    'aria-expanded': isDailyQuestsProgressPopoverOpen,
                    onClick: handleBadgeClick
                }}
            />
            <Popover
                open={isDailyQuestsProgressPopoverOpen}
                anchorEl={dailyQuestsProgressPopoverAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                aria-label={formatMessage(messages.questProgressAriaLabel)} // Aria label also ensures that the screen reader will focus on the popover and not on a skeleton that is removed straight away
            >
                <DailyQuestsProgress
                    currentPoints={currentPoints}
                    currentLevel={currentLevel}
                    pointsRequiredToLevelUp={nextLevelPointsRequired}
                    expandable={false}
                />
            </Popover>
        </>
    )
}

export default memo(ThriveGameProgressNavWidget)
