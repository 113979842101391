import { useMemo } from 'react'
import {
    type AchievementV4ConditionProgressGql,
    useGetCurrentGameAchievementsProgressQuery,
    Frequency,
    type AchievementV4ProgressGql,
    ActionType,
    type AchievementV4Reward
} from '../../../../graphql/generated/autogenerated'
import { captureException, Apollo } from '@thriveglobal/thrive-web-core'

/**
 * Represents a quest that can be completed by the user
 * @property id - Unique identifier for the quest
 * @property actionType - The type of action required to complete the quest
 * @property completedSoFar - Number of times the quest has been completed in the current time window
 * @property maxCanBeCompleted - Maximum number of times the quest can be completed
 * @property rewardPoints - Number of points the quest is worth
 */
export interface Quest {
    id: string
    actionType: ActionType // TODO: Will need to eventually support multiple action types. These will be treated as OR conditions
    completedSoFar: number
    maxCanBeCompleted: number
    rewardPoints?: number
    isCompleted: boolean
    progressPercentage: number
}

interface UseQuestsResult {
    quests: Quest[]
    allQuests: Quest[]
    loading: boolean
    error: Apollo.ApolloError | undefined
}

/**
 * GraphQL reward type that doesn't require achievementId to match what we actually get from the API
 */
type GraphQLReward = Omit<AchievementV4Reward, 'achievementId'> & {
    achievementId?: string // Make achievementId optional
}

/**
 * Subset of AchievementV4ProgressGql containing only the fields needed for quest processing
 * Frequency is omitted as we query for only one frequency at a time
 */
export type AchievementForQuest = Pick<
    AchievementV4ProgressGql,
    'achievementId' | 'priority' | 'maxPerTimeWindow' | 'conditionsProgress'
> & {
    rewards: GraphQLReward[] // Use our adjusted reward type
}

interface QuestsWithAccumulator {
    alreadyFoundLowerPriority: boolean
    achievements: Quest[]
    uncompletedCount: number
}

// Helper Functions

/**
 * Determines if a quest action can be completed multiple times based on its maxPerTimeWindow value
 */
export const canCompleteQuestActionMultipleTimes = (
    achievement: Pick<AchievementV4ProgressGql, 'maxPerTimeWindow'>
): boolean => {
    return achievement.maxPerTimeWindow > 1
}

/**
 * Determines if a quest action can be completed by one type of action
 */
export const isSingleActionTypeQuest = (
    achievementCondition: AchievementV4ConditionProgressGql
): boolean => {
    return achievementCondition.actionTypes.length === 1
}

/**
 * Gets the first condition from an achievement's conditions progress array
 * @returns The first condition or null if no conditions exist
 */
export function getFirstCondition(
    achievement: AchievementForQuest
): AchievementV4ConditionProgressGql | null {
    return achievement.conditionsProgress[0] ?? null
}

/**
 * Converts an achievement to a quest format if possible
 * @returns The quest object or null if conversion is not possible
 */
export function convertAchievementToQuest(
    achievement: AchievementForQuest
): Quest | null {
    const condition = getFirstCondition(achievement)
    if (!condition?.actionTypes.length) {
        return null
    }

    const maxCanBeCompleted = canCompleteQuestActionMultipleTimes(achievement)
        ? achievement.maxPerTimeWindow
        : condition.requiredCount

    return {
        id: achievement.achievementId,
        actionType: condition.actionTypes[0],
        completedSoFar: condition.timesCompletedInCurrentWindow,
        maxCanBeCompleted,
        rewardPoints: achievement.rewards?.[0]?.points,
        isCompleted: isQuestCompleted(achievement),
        progressPercentage:
            (condition.timesCompletedInCurrentWindow / maxCanBeCompleted) * 100
    }
}

/**
 * Checks if a quest has been completed
 * @returns true if completed, false if not completed or if achievement data is invalid
 */
export function isQuestCompleted(achievement: AchievementForQuest): boolean {
    const condition = getFirstCondition(achievement)
    if (!condition) return false

    const maxCanBeCompleted =
        achievement.maxPerTimeWindow * condition.requiredCount // Will be 1 for quests where user gets rewards for every meaningful action
    return condition.timesCompletedInCurrentWindow >= maxCanBeCompleted
}

/**
 * Safely processes a single achievement into a quest
 * @returns The processed quest or null if the achievement cannot be processed
 */
export function safelyProcessAchievement(
    achievement: AchievementForQuest
): Quest | null {
    const condition = getFirstCondition(achievement)
    if (!condition || !isSingleActionTypeQuest(condition)) {
        return null
    }
    return convertAchievementToQuest(achievement)
}

/**
 * Configuration options for the useQuests hook
 */
interface UseQuestsOptions {
    /**
     * Frequency to filter achievements by
     * @default Frequency.TimeWindowBased
     */
    frequency?: Frequency

    /**
     * Maximum number of uncompleted quests to return
     * If not provided, returns only one uncompleted quest (legacy behavior)
     * If set to 'all' or a negative number, returns all quests
     * @default 1
     */
    maxUncompletedQuests?: number | 'all'
}

/**
 * Processes achievements data to generate quests
 */
export function processAchievements(
    achievements: AchievementForQuest[],
    maxUncompletedQuests: number | 'all' = 1
): Quest[] {
    try {
        // Sort achievements by priority (lowest number first)
        const sortedAchievements = [...achievements].sort(
            (a, b) => a.priority - b.priority
        )

        const shouldReturnAll =
            maxUncompletedQuests === 'all' ||
            (typeof maxUncompletedQuests === 'number' &&
                maxUncompletedQuests < 0)

        if (shouldReturnAll) {
            return sortedAchievements
                .map(safelyProcessAchievement)
                .filter((quest): quest is Quest => quest !== null)
        }

        const questsToDisplay =
            sortedAchievements.reduce<QuestsWithAccumulator>(
                (acc, achievement) => {
                    const condition = getFirstCondition(achievement)
                    if (!condition) return acc

                    const quest = safelyProcessAchievement(achievement)
                    if (!quest) return acc

                    const alreadyCompleted = isQuestCompleted(achievement)
                    const alreadyStarted =
                        condition.timesCompletedInCurrentWindow > 0

                    if (!alreadyCompleted && !acc.alreadyFoundLowerPriority) {
                        // Only increment counter if we're adding an uncompleted quest
                        if (acc.uncompletedCount < maxUncompletedQuests) {
                            acc.achievements.push(quest)
                            acc.uncompletedCount++
                            // Only set this flag if we've hit our limit of uncompleted quests
                            if (acc.uncompletedCount >= maxUncompletedQuests) {
                                acc.alreadyFoundLowerPriority = true
                            }
                        }
                    } else if (alreadyCompleted || alreadyStarted) {
                        acc.achievements.push(quest)
                    }
                    return acc
                },
                {
                    alreadyFoundLowerPriority: false,
                    achievements: [],
                    uncompletedCount: 0
                }
            )

        return questsToDisplay.achievements
    } catch (error) {
        captureException(error, {
            message: 'Failed to convert achievement to quest in useQuests hook'
        })
        return []
    }
}

/**
 * Custom hook to fetch and process daily quests data
 * @param options - Configuration options for the hook
 * @returns Object containing quests array, loading state, and any error that occurred. Quests are sorted by priority (lowest number first).
 * - If maxUncompletedQuests is 'all' or negative, returns all valid quests
 * - Otherwise:
 *   - Returns up to maxUncompletedQuests uncompleted quests (default: 1)
 *   - Always includes quests that are completed or already started
 */
export const useQuests = ({
    frequency = Frequency.TimeWindowBased,
    maxUncompletedQuests = 1
}: UseQuestsOptions = {}): UseQuestsResult => {
    const {
        data: achievementsData,
        loading: achievementsLoading,
        error
    } = useGetCurrentGameAchievementsProgressQuery({
        variables: {
            frequencies: [frequency]
        }
    })

    const { quests, allQuests } = useMemo(() => {
        if (!achievementsData?.game.getCurrentGameAchievementsProgress) {
            return {
                quests: [],
                allQuests: []
            }
        }

        return {
            quests: processAchievements(
                achievementsData.game.getCurrentGameAchievementsProgress,
                maxUncompletedQuests
            ),
            allQuests: processAchievements(
                achievementsData.game.getCurrentGameAchievementsProgress,
                'all'
            )
        }
    }, [
        achievementsData?.game.getCurrentGameAchievementsProgress,
        maxUncompletedQuests
    ])

    return {
        quests,
        allQuests,
        loading: achievementsLoading,
        error
    }
}
