import { useCallback } from 'react'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    resourceSelectedDefaultProps,
    resourceServedDefaultProps
} from './defaultTrackingProps'

export const useTrackAcuteCareViewed = () => {
    return useCallback(({ contentId }: { contentId: string }) => {
        Avo.resourceServed({
            ...resourceServedDefaultProps,
            activityType: 'benefits_connect_viewed',
            featureType: 'benefits_connect',
            contentId
        })
    }, [])
}

export const useTrackAcuteCareSelected = () => {
    return useCallback(
        ({
            contentId,
            careOption
        }: {
            contentId: string
            careOption: string
        }) => {
            Avo.resourceSelected({
                ...resourceSelectedDefaultProps,
                activityType: 'benefits_connect_selected',
                featureType: 'benefits_connect',
                contentId,
                contentTitle: careOption
            })
        },
        []
    )
}
