import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl, type MessageDescriptor } from 'react-intl'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { ActionType } from '../../../../graphql/generated/autogenerated'
import { RESET_ROUTES, ROUTES } from '../../../../routes/routes'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'

const messages = defineMessages({
    // Completed action messages
    viewMoreMicrosteps: {
        defaultMessage: 'View more Microsteps',
        description: 'Button text for completed microstep quests'
    },
    viewMoreResets: {
        defaultMessage: 'View more Resets',
        description: 'Button text for completed reset quests'
    },
    viewYourIntention: {
        defaultMessage: 'View your Intention',
        description: 'Button text for completed intention quests'
    },
    viewYourInsights: {
        defaultMessage: 'View your insights',
        description:
            'Button text for completed assessment or pulse survey quests'
    },
    viewMoreContent: {
        defaultMessage: 'View more content',
        description: 'Button text for completed content quests'
    },

    // Uncompleted action messages
    takeAssessment: {
        defaultMessage: 'Take assessment',
        description: 'Button text for uncompleted assessment quests'
    },
    viewYourMicrosteps: {
        defaultMessage: 'View your Microsteps',
        description: 'Button text for uncompleted microstep quests'
    },
    watchReset: {
        defaultMessage: 'Watch a Reset',
        description: 'Button text for uncompleted reset quests'
    },
    discoverContent: {
        defaultMessage: 'Discover content',
        description: 'Button text for uncompleted content quests'
    },
    setYourIntention: {
        defaultMessage: 'Set your Intention',
        description: 'Button text for uncompleted intention quests'
    },
    answerDailyCheckIn: {
        defaultMessage: 'Answer your Daily Check-In',
        description: 'Button text for uncompleted pulse survey quests'
    }
})

type QuestRedirectOptions = {
    // Required fields for incomplete actions
    link: string
    ctaText: MessageDescriptor

    // Optional fields for completed actions
    completedLink?: string
    completedCtaText?: MessageDescriptor
}

const QUEST_ACTION_TYPE_TO_REDIRECT_MAP: Record<
    ActionType,
    QuestRedirectOptions | null
> = {
    // Supported actions with redirect options
    [ActionType.AssessmentCompleted]: {
        link: ROUTES.INSIGHTS,
        ctaText: messages.takeAssessment,
        completedCtaText: messages.viewYourInsights
    },
    [ActionType.CheckIn]: {
        link: ROUTES.JOURNEYS,
        completedLink: ROUTES.JOURNEY_MICROSTEPS_BROWSE,
        ctaText: messages.viewYourMicrosteps,
        completedCtaText: messages.viewMoreMicrosteps
    },
    [ActionType.ContentFinished]: {
        link: ROUTES.LIBRARY,
        ctaText: messages.discoverContent,
        completedCtaText: messages.viewMoreContent
    },
    [ActionType.IntentionSettingCompleted]: {
        link: ROUTES.SET_INTENTION,
        ctaText: messages.setYourIntention,
        completedCtaText: messages.viewYourIntention
    },
    [ActionType.PulseSurveyCompleted]: {
        link: ROUTES.JOURNEYS,
        completedLink: ROUTES.INSIGHTS,
        ctaText: messages.answerDailyCheckIn,
        completedCtaText: messages.viewYourInsights
    },
    [ActionType.ResetWatched]: {
        link: RESET_ROUTES.RESETS,
        ctaText: messages.watchReset,
        completedCtaText: messages.viewMoreResets
    },

    // Unsupported actions (no redirect)
    [ActionType.CourseCompleted]: null,
    [ActionType.JourneyLevelCompleted]: null,
    [ActionType.PersonalResetCreated]: null,
    [ActionType.ProfileImageAdded]: null,
    [ActionType.ReferralCompleted]: null,
    [ActionType.SocialSharingCompleted]: null,
    [ActionType.WearableConnected]: null,
    [ActionType.WorkDetailsCompleted]: null
} as const

export function getButtonMessage(
    actionType: ActionType,
    isCompleted: boolean
): MessageDescriptor | null {
    const redirectConfig = QUEST_ACTION_TYPE_TO_REDIRECT_MAP[actionType]
    if (!redirectConfig) return null

    // Use completed text if available and quest is completed, otherwise use standard text
    return isCompleted && redirectConfig.completedCtaText
        ? redirectConfig.completedCtaText
        : redirectConfig.ctaText
}

export function getQuestRedirectLink(
    actionType: ActionType,
    isCompleted: boolean
): string | null {
    const redirectConfig = QUEST_ACTION_TYPE_TO_REDIRECT_MAP[actionType]
    if (!redirectConfig) return null

    // Use completed link if available and quest is completed, otherwise use standard link
    return isCompleted && redirectConfig.completedLink
        ? redirectConfig.completedLink
        : redirectConfig.link
}

export interface QuestActionButtonProps {
    actionType: ActionType
    isCompleted: boolean
    onActionForCurrentRoute?: () => void
}

export function QuestActionButton({
    actionType,
    isCompleted,
    onActionForCurrentRoute
}: QuestActionButtonProps) {
    const { formatMessage } = useIntl()
    const navigate = useCrossAppNavigation()
    const location = useLocation()
    const buttonMessage = getButtonMessage(actionType, isCompleted)

    const handleClick = useCallback(() => {
        const redirectLink = getQuestRedirectLink(actionType, isCompleted)
        if (redirectLink) {
            // If we're already on the target page, just call the action
            if (location.pathname === redirectLink) {
                onActionForCurrentRoute?.()
            } else {
                // Otherwise just navigate without calling the action
                navigate(redirectLink)
            }
        }
    }, [
        actionType,
        isCompleted,
        location.pathname,
        navigate,
        onActionForCurrentRoute
    ])

    // If there's no message for this action, don't render a button
    if (!buttonMessage) {
        return null
    }

    return (
        <Button
            sx={{
                marginLeft: 'auto'
            }}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            size="small"
        >
            <CoreTypography customVariant="buttonSmall">
                {formatMessage(buttonMessage)}
            </CoreTypography>
        </Button>
    )
}
