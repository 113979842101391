import { FormattedMessage } from 'react-intl'
import { ActionType } from '../../../graphql/generated/autogenerated'

interface ActionLabelProps {
    actionType: ActionType
    requiredCount: number
}

export const ActionLabel = ({
    actionType,
    requiredCount
}: ActionLabelProps) => {
    // Handle specific action types that need custom plural/singular forms
    switch (actionType) {
        case ActionType.ContentFinished:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Consume {count} pieces of Thrive Content"
                    description="Quest title for finishing multiple content items"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Consume Thrive Content"
                    description="Quest title for finishing one content item"
                />
            )

        case ActionType.CheckIn:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Complete {count} Microsteps"
                    description="Quest title for completing multiple microsteps"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Complete a Microstep"
                    description="Quest title for completing one microstep"
                />
            )

        // TODO: Will this be used for MVP?
        case ActionType.CourseCompleted:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Complete {count} lessons"
                    description="Quest title for completing multiple lessons"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Complete a lesson"
                    description="Quest title for completing one lesson"
                />
            )

        case ActionType.AssessmentCompleted:
            return (
                <FormattedMessage
                    defaultMessage="Take your baseline assessment"
                    description="Quest title for completing an assessment"
                />
            )

        case ActionType.IntentionSettingCompleted:
            return (
                <FormattedMessage
                    defaultMessage="Set your intention"
                    description="Quest title for setting an intention"
                />
            )

        // TODO: Will this be used for MVP?
        case ActionType.JourneyLevelCompleted:
            return (
                <FormattedMessage
                    defaultMessage="Complete a journey level"
                    description="Quest title for completing one journey level"
                />
            )

        case ActionType.PersonalResetCreated:
            return (
                <FormattedMessage
                    defaultMessage="Create a personal Reset"
                    description="Quest title for creating a personal reset"
                />
            )

        case ActionType.ProfileImageAdded:
            return (
                <FormattedMessage
                    defaultMessage="Add a profile image"
                    description="Quest title for adding a profile image"
                />
            )

        case ActionType.PulseSurveyCompleted:
            return (
                <FormattedMessage
                    defaultMessage="Answer your Daily Check-In"
                    description="Quest title for completing one pulse survey"
                />
            )

        case ActionType.ReferralCompleted:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Refer {count} friends"
                    description="Quest title for referring multiple friends"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Refer a friend"
                    description="Quest title for referring one friend"
                />
            )

        case ActionType.ResetWatched:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Watch {count} Resets"
                    description="Quest title for watching multiple resets"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Watch a Reset"
                    description="Quest title for watching one reset"
                />
            )

        case ActionType.SocialSharingCompleted:
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Share {count} times"
                    description="Quest title for sharing multiple times"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Share socially"
                    description="Quest title for sharing once"
                />
            )

        case ActionType.WearableConnected:
            return (
                <FormattedMessage
                    defaultMessage="Connect a wearable device"
                    description="Quest title for connecting a wearable device"
                />
            )

        case ActionType.WorkDetailsCompleted:
            return (
                <FormattedMessage
                    defaultMessage="Complete your work details"
                    description="Quest title for completing work details"
                />
            )

        default: // TODO: Default if we don't have a specific case for the action type. Eg. New type added to the backend before we have a case for it.
            return requiredCount > 1 ? (
                <FormattedMessage
                    defaultMessage="Complete {count} actions"
                    description="Default quest title for multiple unknown actions"
                    values={{ count: requiredCount }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Complete an action"
                    description="Default quest title for one unknown action"
                />
            )
    }
}
