import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import { useMemo } from 'react'
import { useAppSelector } from '../../slices/store'
import { selectThriveGameAttributes } from '../../slices/thriveGame'
import {
    hasNoActiveGame,
    useSyncGameProgressState
} from './useSyncGameProgressState'

export const DEFAULT_DEBOUNCE_TIME = 300

export type UseAutoUpdateGameProgressOnActionOptions = {
    debounceTime?: number
}

export type UseAutoUpdateGameProgressOnActionResult = () => void

/**
 * This hook provides a function to update the game progress on action with side effects.
 * @param options - The options for the hook.
 * @returns The debounced triggerProgressUpdate function.
 * @example
 * const autoUpdateGameProgress = useAutoUpdateGameProgressOnAction()
 * // ...
 * checkMicrosteps({ microstepIds: [1, 2, 3] }).then(autoUpdateGameProgress)
 */
export function useAutoUpdateGameProgressOnAction(
    options?: UseAutoUpdateGameProgressOnActionOptions
): UseAutoUpdateGameProgressOnActionResult {
    const [triggerProgressUpdate] = useSyncGameProgressState({
        skipOnMount: true
    })

    const gameAttributes = useAppSelector(selectThriveGameAttributes)

    const triggerProgressUpdateDebounced = useMemo(() => {
        // If there is no active game, we don't need to update the game progress
        if (hasNoActiveGame(gameAttributes)) {
            return noop
        }

        return debounce(
            triggerProgressUpdate,
            options?.debounceTime || DEFAULT_DEBOUNCE_TIME
        )
    }, [gameAttributes, options?.debounceTime, triggerProgressUpdate])

    return triggerProgressUpdateDebounced
}
