import { Stack } from '@mui/material'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import { QuestTabs } from './QuestTabs'

const messages = defineMessages({
    modalTitle: {
        defaultMessage: 'How to earn points in Thrive',
        description: 'Quest overview modal title'
    }
})

export interface QuestOverviewModalProps {
    onClose: () => void
    open: boolean
}

export const QuestOverviewModal = ({
    onClose,
    open
}: QuestOverviewModalProps) => {
    const intl = useIntl()

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            dialogTitle={intl.formatMessage(messages.modalTitle)}
        >
            <Stack spacing={3}>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="Earn points and level up for using Thrive. Some activities can earn you points daily, while others are earned on a quarterly or one time basis."
                        description="Quest overview modal introduction text"
                    />
                </CoreTypography>

                <QuestTabs onActionForCurrentRoute={onClose} />
            </Stack>
        </LeafDialog>
    )
}
