import { Avatar } from '@mui/material'
import {
    CoreTypography,
    LeafChip,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { ThriveSvgIcon } from './ThriveSvgIcon'
import { ProgressIndicator } from './ProgressIndicator'
import { defineMessages, useIntl } from 'react-intl'
import { type ComponentProps } from 'react'
import { TEMP_GAME_COLOR } from '../DailyQuestsProgress/LevelInfo'
const messages = defineMessages({
    pointsBadgeAriaLabel: {
        defaultMessage: '{points} out of {totalPoints} points earned so far.',
        description: 'Points badge aria label'
    },
    pointsBadgeAriaLabelGameFinished: {
        defaultMessage: '{points} points earned. Game completed.',
        description: 'Points badge aria label for game finished'
    }
})

export type GameProgressBadgeProps = {
    points: number
    totalPoints: number
    indicatorPoints: number
    indicatorTotalPoints: number
    isGameFinished?: boolean
    chipProps?: ComponentProps<typeof LeafChip>
}

function GameProgressBadge(props: GameProgressBadgeProps) {
    const {
        points,
        totalPoints,
        isGameFinished = false,
        indicatorPoints,
        indicatorTotalPoints,
        chipProps
    } = props

    const { palette } = useTheme()
    const { formatMessage } = useIntl()

    // TODO: Replace with the color from the DS
    const progressColor = isGameFinished
        ? palette.success.main
        : TEMP_GAME_COLOR

    return (
        <ProgressIndicator
            currentProgress={indicatorPoints}
            totalProgress={indicatorTotalPoints}
            borderRadius={31}
            progressWidth={6}
            progressColor={progressColor}
            backgroundColor={palette.background.default}
        >
            <LeafChip
                sx={{
                    minWidth: 103,
                    minHeight: 34,
                    borderRadius: 25,
                    '& > .MuiChip-avatar': {
                        backgroundColor: 'transparent'
                    }
                }}
                aria-live="polite"
                aria-label={
                    isGameFinished
                        ? formatMessage(
                              messages.pointsBadgeAriaLabelGameFinished,
                              {
                                  points
                              }
                          )
                        : formatMessage(messages.pointsBadgeAriaLabel, {
                              points,
                              totalPoints
                          })
                }
                label={
                    <CoreTypography variant="body2" fontWeight={'bold'}>
                        {points}
                    </CoreTypography>
                }
                avatar={
                    <Avatar sx={{ backgroundColor: 'transparent' }}>
                        <ThriveSvgIcon fill={progressColor} />
                    </Avatar>
                }
                selectable={false}
                color={'secondary'}
                variant="filled"
                size="medium"
                {...chipProps}
            />
        </ProgressIndicator>
    )
}

export default GameProgressBadge
